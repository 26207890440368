import React from 'react'
import styled from '@emotion/styled'
import mq from 'styles/breakpoints'
import Loader from 'react-loader-spinner'

export const GradientButton = styled.button`
  font-size: ${(props) => props.fontSize || '2.1rem'};
  padding: ${(props) => props.padding || '0.8rem 3rem'};
  text-decoration: none;
  margin-top: ${(props) => props.marginTop || '2rem'};
  width: ${(props) => props.width || null};
  border-radius: 50px;
  background-image: linear-gradient(to right, #00daa0 0%, #00b7ff 100%);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    transform: scale(1.02);
  }

  :disabled {
    background-color: #d8d8d8;
    background-image: none;
    cursor: default;
  }

  ${mq['sm']} {
    font-size: 2rem;
  }

  ${mq['xsm']} {
    font-size: 1.6rem;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 15rem;
`

export const LineBreak = styled.span`
  background-color: #f5f5f5;
  width: 80%;
  height: 3.5px;
`

export const CouponButton = styled.button`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #0080fa;
  background-color: #fafafa;
  border: none;
  cursor: pointer;
`

export const SubscriptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${mq['xxl']} {
    justify-content: space-evenly;
  }
`

export const Spinner = () => (
  <Loader type="Oval" color="#ffffff" height={20} width={20} timeout={30000} />
)

export const SectionIcon = styled.div`
  img {
    height: 10rem;
    margin-top: 6rem;
  }
`

export const SubscriptionOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32rem;
  border: 2px solid #f5f5f5;
  outline: 2px solid #0080fa;
  background-color: #fafafa;
  height: 52rem;

  h4 {
    font-size: 3.2rem;
    color: #213054;
    margin-top: 3rem;
  }

  h3 {
    font-size: 4.8rem;
    font-weight: 300;
    margin-top: 1rem;
    color: black;
  }

  h6 {
    color: #b9b9b9;
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
  }

  ul {
    list-style: none;
    font-size: 2rem;
    margin-top: 1.5rem;
    width: 25rem;
    height: 9rem;
  }

  p {
    font-size: 2.1rem;
    text-align: center;
    margin-top: 1.5rem;
    width: 27rem;
  }

  h5 {
    color: #b9b9b9;
    font-weight: 700;
    font-size: 1.8rem;
    margin-top: 2rem;
  }

  ${mq['md']} {
    width: 28rem;
    margin-left: 0;
    margin-bottom: 1rem;

    h4 {
      font-size: 2.7rem;
    }

    h3 {
      font-size: 4rem;
    }

    h6 {
      font-size: 1.5rem;
    }

    ul {
      font-size: 1.8rem;
    }

    p {
      font-size: 1.8rem;
    }

    h5 {
      font-size: 1.5rem;
    }

    button {
      font-size: 2.1rem;
    }
  }

  ${mq['xxl']} {
    margin-top: 4rem;
  }
`

export const FreeTrialDisclaimer = styled.div`
  margin-bottom: 8px;

  p {
    color: gray;
    margin: 0;
    font-size: 1rem;
    text-align: right;
  }
`
